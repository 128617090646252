import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {ContainerComponent} from './view/common/container/container.component';
import {FooterComponent} from './view/common/footer/footer.component';
import {HeaderComponent} from './view/common/header/header.component';
import {NavigationComponent} from './view/common/navigation/navigation.component';
import {SnotifyModule, SnotifyService, ToastDefaults} from 'ng-snotify';
import {NgxPaginationModule} from 'ngx-pagination';
import {AppRoutingModule} from './app-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {NgxSpinnerModule} from 'ngx-spinner';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {TOnlineOrderGrnService} from './view/trasactions/t-online-pending-order-print/service/t-online-order-grn.service';
import {SafePipe} from './pipe/safe.pipe';
import {LoginComponent} from './view/security/login/login.component';
import {UrlPermission} from './view/security/urlPermission/url.permission';
import {AccountService} from './view/security/services/account.service';
import {AuthService} from './view/security/services/auth.service';
import {TOnlinePendingOrderPrintComponent} from './view/trasactions/t-online-pending-order-print/t-online-pending-order-print.component';
import {MReportService} from './view/report/service/m-report.service';
import {HashLocationStrategy, LocationStrategy} from "@angular/common";
import {ngxLoadingAnimationTypes, NgxLoadingModule} from "ngx-loading";
import {TDeliveryCompanyPaymentReconciliationComponent} from './view/trasactions/t-delivery-company-payment-reconciliation/t-delivery-company-payment-reconciliation.component';
import {FilterPipe} from "./pipe/filter-pipe";
import { MProductIntigrationComponent } from './view/trasactions/m-product-intigration/m-product-intigration.component';

@NgModule({
  declarations: [
    AppComponent,
    ContainerComponent,
    FooterComponent,
    HeaderComponent,
    NavigationComponent,
    SafePipe,
    FilterPipe,
    LoginComponent,
    TOnlinePendingOrderPrintComponent,
    TDeliveryCompanyPaymentReconciliationComponent,
    MProductIntigrationComponent,
  ],
  imports: [
    BrowserModule,
    SnotifyModule,
    NgxPaginationModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    NgbModule.forRoot(),
    NgxSmartModalModule.forRoot(),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#ffffff',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff'
    })
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {
      provide: 'SnotifyToastConfig',
      useValue: ToastDefaults
    },
    TOnlineOrderGrnService,
    SnotifyService,
    MReportService,
    AuthService,
    AccountService,
    UrlPermission],
  bootstrap: [AppComponent]
})
export class AppModule {
}

platformBrowserDynamic().bootstrapModule(AppModule).then(ref => {
  // Ensure Angular destroys itself on hot reloads.
  if (window['ngRef']) {
    window['ngRef'].destroy();
  }
  window['ngRef'] = ref;

  // Otherise, log the boot error
}).catch(err => console.error(err));
