import {MDeliveryCompany} from "../../t-online-pending-order-print/model/m-delivery-company";
import {TDeliveryCompanyPaymentReconciliationDetail} from "./t-delivery-company-payment-reconciliation-detail";
import {TDeliveryCompanyPaymentReconciliationAccounts} from "./t-delivery-company-payment-reconciliation-accounts";

export class TDeliveryCompanyPaymentReconciliation {

  index_no: number;
  invoice_no: number;
  invoice_date: string;
  remarks: string;
  settlement_amount: number;
  delivery_comapany: MDeliveryCompany;
  delivery_company_payment_reconciliation_detail_list: TDeliveryCompanyPaymentReconciliationDetail [] = [];
  delivery_company_payment_reconciliation_accounts_list: TDeliveryCompanyPaymentReconciliationAccounts [] = [];
  status: string;
  sys_cod_order_amount_total: number = 0;
  rec_cod_order_amount_total: number = 0;
  sys_delivery_charges_total: number = 0;
  rec_delivery_charges_total: number = 0;
  sys_net_amount_total: number = 0;
  rec_net_amount_total: number = 0;
  sys_settlement_amount_total: number = 0;
  //temp
  csv: string;
}
