import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-m-product-intigration',
  templateUrl: './m-product-intigration.component.html'
})
export class MProductIntigrationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
