import {MDeliveryCompany} from "./m-delivery-company";
import {Injectable} from "@angular/core";

@Injectable()
export class TOnlineOrder {
  order_number: number;
  name: string;
  shopifyJson: string;
  order_status_url: string;
  delivery_status: string
  print_barcode_status: string;
  print_packing_slip_status: string;
  //temp
  shopifyJsonObject: ShopifyJsonObject;
  orderSelect: boolean = false;
  orderCheck: boolean = false;
  tOnlineOrderItemList: any;
  deliveryCompany: MDeliveryCompany
}

export class ShopifyJsonObject {
  shipping_address: any;
  billing_address: any;
  total_price: number;
  total_line_items_price: number;
  line_items: any [] = [];
  shipping_lines: any;
  customer: any;
  financial_status: string;
}
