import {TDeliveryCompanyPaymentReconciliation} from "./t-delivery-company-payment-reconciliation";

export class TDeliveryCompanyPaymentReconciliationDetail {

  index_no: number;
  reconciliation_index_no: number;
  reconciliation_order_number;
  reconciliation_delivery_refarance_no;
  reconciliation_cod_received: number;
  reconciliation_billable_amount: number;
  reconciliation_nbt: number;
  reconciliation_net_amount: number;
  delivery_company_payment_reconciliation: TDeliveryCompanyPaymentReconciliation;

  //temp
  isSave: string;
}
