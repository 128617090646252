import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {AppSettings} from "../../../../settings/app-settings";
import {TDeliveryCompanyPaymentReconciliation} from "../dto/t-delivery-company-payment-reconciliation";
import {TDeliveryCompanyPaymentReconciliationAccounts} from "../dto/t-delivery-company-payment-reconciliation-accounts";

@Injectable({
  providedIn: 'root'
})
export class TDeliveryCompanyPaymentReconciliationService {

  constructor(private http: HttpClient) {
  }

  public saveTDeliveryCompanyPaymentReconciliation(tDeliveryCompanyPaymentReconciliation: TDeliveryCompanyPaymentReconciliation): Observable<number> {
    return this.http.post<number>(AppSettings.API_ENDPOINT + '/api/v1/gflock-online-order-manage-system/save-t-delivery-company-payment-reconciliation', tDeliveryCompanyPaymentReconciliation);
  }

  public findTDeliveryCompanyPaymentReconciliationByDeliveryComapanyIndexNo(mDeliveryComapanyIndexNo: number): Observable<TDeliveryCompanyPaymentReconciliation[]> {
    return this.http.get<TDeliveryCompanyPaymentReconciliation[]>(AppSettings.API_ENDPOINT + '/api/v1/gflock-online-order-manage-system/find-t-delivery-company-payment-reconciliation/' + mDeliveryComapanyIndexNo);
  }

  public findBeforeByIndexNoTDeliveryCompanyPaymentReconciliation(indexNo: number): Observable<TDeliveryCompanyPaymentReconciliation> {
    return this.http.get<TDeliveryCompanyPaymentReconciliation>(AppSettings.API_ENDPOINT + '/api/v1/gflock-online-order-manage-system/find-before-t-delivery-company-payment-reconciliation-by-index-no/' + indexNo);
  }

  public findAfterByIndexNoTDeliveryCompanyPaymentReconciliation(indexNo: number): Observable<TDeliveryCompanyPaymentReconciliation> {
    return this.http.get<TDeliveryCompanyPaymentReconciliation>(AppSettings.API_ENDPOINT + '/api/v1/gflock-online-order-manage-system/find-after-t-delivery-company-payment-reconciliation-by-index-no/' + indexNo);
  }
}
