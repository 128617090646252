export class AppSettings {

  // private location: Location;
  //
  // constructor(location: Location) {
  //   this.location = location;
  // }

  public static API_ENDPOINT =  'http://5.9.139.157:8081';
  // public static API_ENDPOINT =
  //   location.hostname ===
  //   'localhost' ? 'http://localhost:8086'  : location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');

  //SnotifyService notification and confirm dialog
  public static MESSAGE_SUCCESS = 'SUCCESS';
  public static MESSAGE_ERROR = 'ERROR';
  public static MESSAGE_INFO = 'INFO';
  public static MESSAGE_WARNING = 'WARNING';

  //report names
  public static REPORT_GFLOCK_ONLINE_PENDING_ORDER_PRE_PRINT = 'gflock-online-pending-order';
  public static REPORT_GFLOCK_ONLINE_GRASSHOPPERS_BARCODE = 'gflock-online-grasshoppers-barcode';

}
