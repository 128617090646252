import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router, RoutesRecognized} from '@angular/router';
import {TDeliveryCompanyPaymentReconciliationComponent} from "../../trasactions/t-delivery-company-payment-reconciliation/t-delivery-company-payment-reconciliation.component";
import {TOnlinePendingOrderPrintComponent} from "../../trasactions/t-online-pending-order-print/t-online-pending-order-print.component";

export enum NavigationMenuTypes {
  T_ONLINE_ORDER_GRN,
  PAYMENT_RECONCILIATION,
  OVERVIEW
}

export const NavigationMenus = [
  {
    title: 'ONLINE ORDER',
    description: ''
  },
  {
    title: 'PAYMENT RECONCILIATION',
    description: ''
  },
  {
    title: 'OVERVIEW',
    description: ''
  }
];

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {

  NavigationMenuTypes: typeof NavigationMenuTypes = NavigationMenuTypes;
  public activeMenu: NavigationMenuTypes;
  @Output() public changeMenu = new EventEmitter<NavigationMenuTypes>();

  constructor(private router: Router) {
  }

  ngOnInit() {
    // a little hack
    this.router.events.subscribe((event) => {
      if (event instanceof RoutesRecognized) {
        switch (event.state.root.firstChild.component) {
          case TOnlinePendingOrderPrintComponent:
            this.setActiveMenu(NavigationMenuTypes.T_ONLINE_ORDER_GRN);
            break;
          case TDeliveryCompanyPaymentReconciliationComponent:
            this.setActiveMenu(NavigationMenuTypes.PAYMENT_RECONCILIATION);
            break;
          default:
            this.setActiveMenu(NavigationMenuTypes.OVERVIEW);
            break;
        }
      }
    });

  }

  setActiveMenu(activeMenu: NavigationMenuTypes): void {
    this.activeMenu = activeMenu;
    this.changeMenu.emit(this.activeMenu);
  }

  isMainFunctionsActive(): boolean {
    return this.activeMenu !== NavigationMenuTypes.OVERVIEW;
  }


}
