import {Injectable} from '@angular/core';
import {HttpClient} from '../../../../../node_modules/@angular/common/http';
import {Observable} from 'rxjs';
import {AppSettings} from '../../../settings/app-settings';
import {MReport} from '../model/m-report';
import {MReportGroup} from "../model/m-report-group";
import {MCurrentReportParamiterValues} from "../model/m-current-report-paramiter-values";

@Injectable({
  providedIn: 'root'
})
export class MReportService {

  constructor(private http: HttpClient) {
  }


  public listReports(): Observable<MReportGroup[]> {
    return this.http.get<MReportGroup[]>(AppSettings.API_ENDPOINT + '/api/v1/report/report-viewer/list');
  }

  public listParameters(mReport: MReport): Observable<any> {
    return this.http.post<any>(AppSettings.API_ENDPOINT + '/api/v1/report/report-viewer/report-parameters', mReport);
  }

  public getReportUrl(report: MReport, parameters: string[], parameterValues: MCurrentReportParamiterValues): string {
    let url = AppSettings.API_ENDPOINT + '/api/v1/report/report-viewer/report';
    let action = btoa(report.fileName);
    url = url + '?action=' + action;
    for (var key of Object.keys(parameterValues)) {
      url = url + '&' + key + '=' + parameterValues[key];
    }
    return url;
  }

  public viewReport(report: MReport, parameters: string[], parameterValues: MCurrentReportParamiterValues): any {
    return this.http.get(this.getReportUrl(report, parameters, parameterValues), {responseType: 'arraybuffer'});
  }

  public individualReport(reportName: string): Observable<MReport> {
    return this.http.get<MReport>(AppSettings.API_ENDPOINT + '/api/v1/report/report-viewer/individual-report-data/' + reportName);
  }

}
