import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppSettings} from '../../../../settings/app-settings';
import {TOnlineOrder} from '../model/t-online-order';
import {MDeliveryCompany} from '../model/m-delivery-company';
import {TempShippingBillingAddress} from "../model/temp-shipping-billing-address";
import {TempDeliveryRequest} from "../model/temp-delivery-request";
import {MPosItem} from "../model/m-pos-item";

@Injectable({
  providedIn: 'root'
})
export class TOnlineOrderGrnService {

  constructor(private http: HttpClient) {
  }

  public onlinePendingOrdersPagination(
    orderNumber: number,
    fromDate: string,
    toDate: string,
    paymentStatus: string,
    paymentType: string,
    delivaryStatus: string,
    page: number,
    size: number): Observable<any> {

    let params = new HttpParams();
    params = params.append('page', page.toString());
    params = params.append('size', size.toString());

    if (orderNumber) {
      params = params.append('orderNumber', orderNumber.toString());
    }
    if (fromDate) {
      params = params.append('fromDate', fromDate);
    }
    if (toDate) {
      params = params.append('toDate', toDate);
    }
    if (paymentStatus) {
      params = params.append('paymentStatus', paymentStatus);
    }
    if (paymentType) {
      params = params.append('paymentType', paymentType);
    }
    if (delivaryStatus) {
      params = params.append('delivaryStatus', delivaryStatus);
    }
    return this.http.get<any>(AppSettings.API_ENDPOINT + '/api/v1/gflock-online-order-manage-system/online-pending-order-find-by-oder-number-and-from-and-to-date', {params: params});
  }

  public allOnlineOrders(
    orderNumber: number,
    fromDate: string,
    toDate: string,
    paymentStatus: string,
    paymentType: string,
    delivaryStatus: string,
    page: number,
    size: number): Observable<any> {

    let params = new HttpParams();
    params = params.append('page', page.toString());
    params = params.append('size', size.toString());

    if (orderNumber) {
      params = params.append('orderNumber', orderNumber.toString());
    }
    if (fromDate) {
      params = params.append('fromDate', fromDate);
    }
    if (toDate) {
      params = params.append('toDate', toDate);
    }
    if (paymentStatus) {
      params = params.append('paymentStatus', paymentStatus);
    }
    if (paymentType) {
      params = params.append('paymentType', paymentType);
    }
    if (delivaryStatus) {
      params = params.append('delivaryStatus', delivaryStatus);
    }

    return this.http.get<TOnlineOrder[]>(AppSettings.API_ENDPOINT + '/api/v1/gflock-online-order-manage-system/find-shopify-online-orders', {params: params});
  }

  public updateBillingAddressAndShippingAddress(tempShippingBillingAddress: TempShippingBillingAddress): Observable<number> {
    return this.http.post<number>(AppSettings.API_ENDPOINT + '/api/v1/gflock-online-order-manage-system/update-billing-address-and-shipping-address', tempShippingBillingAddress);
  }

  public syncShopifyOnlineOrderManual(): Observable<number> {
    return this.http.get<number>(AppSettings.API_ENDPOINT + '/api/v1/gflock-online-order-manage-system/sync-shopify-online-orders');
  }

  //master files
  public allMDeliveryCompany(): Observable<MDeliveryCompany[]> {
    return this.http.get<MDeliveryCompany[]>(AppSettings.API_ENDPOINT + '/api/v1/gflock-online-order-manage-system/find-all-delivery-company');
  }

  public mDeliveryRequestSent(tempDeliveryRequestList: TempDeliveryRequest []): Observable<TempDeliveryRequest []> {
    return this.http.post<TempDeliveryRequest []>(AppSettings.API_ENDPOINT + '/api/v1/gflock-online-order-manage-system/m-delivery-request-sent/', tempDeliveryRequestList);
  }

  public mDeliveryRequestReturn(tempDeliveryRequestList: TempDeliveryRequest []): Observable<TempDeliveryRequest []> {
    return this.http.post<TempDeliveryRequest []>(AppSettings.API_ENDPOINT + '/api/v1/gflock-online-order-manage-system/m-delivery-request-return/', tempDeliveryRequestList);
  }

  public updateShopifyOnlineOrderPrintPackingSlipStatusAndPrintBarcodeStatus(statusType: string, orders: string): Observable<string> {
    return this.http.get<string>(AppSettings.API_ENDPOINT + '/api/v1/gflock-online-order-manage-system/update-print-packing-slip-or-print-barcode/' + statusType + '/' + orders);
  }

  public addNewMPosItem(orderNumber: string, mPosItem: number, quantity: number, price: number): Observable<string> {
    return this.http.get<string>(AppSettings.API_ENDPOINT + '/api/v1/gflock-online-order-manage-system/add-new-m-pos-item/' + orderNumber + '/' + mPosItem + '/' + quantity + '/' + price);
  }

  public removeTOnlineOrderItem(orderNumber: number, tOnlineOrderItemIndexNo: number, status: string): Observable<any> {
    return this.http.get<any>(AppSettings.API_ENDPOINT + '/api/v1/gflock-online-order-manage-system/remove-m-pos-item/' + orderNumber + '/' + tOnlineOrderItemIndexNo + '/' + status);
  }

  public updateTotalPricesAndShippingLineDetails(TOnlineOrderItemIndexNo: number): Observable<any> {
    return this.http.get<any>(AppSettings.API_ENDPOINT + '/api/v1/gflock-online-order-manage-system/update-total/' + TOnlineOrderItemIndexNo);
  }

  public findMPosItems(barcode: string, details: string, printDescription: string, imageCode: string): Observable<MPosItem[]> {

    let params = new HttpParams();

    if (barcode) {
      params = params.append('barcode', barcode.toString());
    }

    if (details) {
      params = params.append('details', details.toString());
    }

    if (printDescription) {
      params = params.append('printDescription', printDescription.toString());
    }

    if (imageCode) {
      params = params.append('imageCode', imageCode.toString());
    }

    return this.http.get<MPosItem[]>(AppSettings.API_ENDPOINT + '/api/v1/gflock-online-order-manage-system/find-m-pos-item', {params: params});
  }

}
