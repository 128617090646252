import {Injectable} from "@angular/core";

@Injectable()
export class TempShippingBillingAddress {
  //order number
  orderNumber: string = '';
  //shipping address details
  shippingAddressName: string = '';
  shippingAddressCompany: string = '';
  shippingAddressAddress1: string = '';
  shippingAddressAddress2: string = '';
  shippingAddressCity: string = '';
  shippingAddressZip: string = '';
  shippingAddressCountry: string = '';
  shippingAddressPhone: string = '';
  //billing address details
  billingAddressName: string = '';
  billingAddressCompany: string = '';
  billingAddressAddress1: string = '';
  billingAddressAddress2: string = '';
  billingAddressCity: string = '';
  billingAddressZip: string = '';
  billingAddressCountry: string = '';
  billingAddressPhone: string = '';
  financialStatus: string = '';
}
