import {Component, OnInit, ViewChild} from '@angular/core';
import {MDeliveryCompany} from "../t-online-pending-order-print/model/m-delivery-company";
import {TOnlineOrderGrnService} from "../t-online-pending-order-print/service/t-online-order-grn.service";
import {NgxSmartModalService} from "ngx-smart-modal";
import {SnotifyService} from "ng-snotify";
import {NgxSpinnerService} from "ngx-spinner";
import {TDeliveryCompanyPaymentReconciliationService} from "./service/t-delivery-company-payment-reconciliation.service";
import {AppSettings} from "../../../settings/app-settings";
import {TDeliveryCompanyPaymentReconciliation} from "./dto/t-delivery-company-payment-reconciliation";
import {TDeliveryCompanyPaymentReconciliationDetail} from "./dto/t-delivery-company-payment-reconciliation-detail";
import {TDeliveryCompanyPaymentReconciliationAccounts} from "./dto/t-delivery-company-payment-reconciliation-accounts";

@Component({
  selector: 'app-t-delivery-company-payment-reconciliation',
  templateUrl: './t-delivery-company-payment-reconciliation.component.html'
})
export class TDeliveryCompanyPaymentReconciliationComponent implements OnInit {

  @ViewChild('inputForm') inputForm;

  public mDeliveryCompanys: MDeliveryCompany [] = [];
  public mDeleveryCompanySelect: MDeliveryCompany;

  public mDeleveryCompanySelectForTDeliveryCompanyPaymentReconciliationsListSearch: MDeliveryCompany;
  public tDeliveryCompanyPaymentReconciliation: TDeliveryCompanyPaymentReconciliation;
  public tDeliveryCompanyPaymentReconciliationList: TDeliveryCompanyPaymentReconciliation [] = [];
  public tDeliveryCompanyPaymentReconciliationAccountList: TDeliveryCompanyPaymentReconciliationAccounts [] = [];
  public filter: string;

  constructor(
    private ngxSmartModalService: NgxSmartModalService,
    private snotifyService: SnotifyService,
    private spinner: NgxSpinnerService,
    private tOnlineOrderGrnService: TOnlineOrderGrnService,
    private tDeliveryCompanyPaymentReconciliationService: TDeliveryCompanyPaymentReconciliationService) {
    this.mDeleveryCompanySelect = new MDeliveryCompany();
    this.mDeleveryCompanySelectForTDeliveryCompanyPaymentReconciliationsListSearch = new MDeliveryCompany();
    this.tDeliveryCompanyPaymentReconciliation = new TDeliveryCompanyPaymentReconciliation();
  }

  ngOnInit() {
    this.tOnlineOrderGrnService.allMDeliveryCompany().subscribe((data) => {
      this.mDeliveryCompanys = data;
    }, (e) => {
      console.log('Delivery Company Not Found!')
    });
  }

  public convertFile(csv: any) {

    var fileReaded = csv.target.files[0];

    let reader: FileReader = new FileReader();
    reader.readAsText(fileReaded);

    reader.onload = (e) => {
      let csv: string = reader.result;
      let allTextLines = csv.split(/\r|\n|\r/);
      let headers = allTextLines[0].split(',');

      for (let i = 1; i < allTextLines.length; i++) {
        let data = allTextLines[i].split(',');
        if (data.length === headers.length) {
          let tarr = [];
          for (let j = 0; j < headers.length; j++) {
            tarr.push(data[j]);
          }

          var tDeliveryCompanyPaymentReconciliationDetail = new TDeliveryCompanyPaymentReconciliationDetail();
          tDeliveryCompanyPaymentReconciliationDetail.reconciliation_index_no = tarr[0];
          tDeliveryCompanyPaymentReconciliationDetail.reconciliation_order_number = tarr[1];
          tDeliveryCompanyPaymentReconciliationDetail.reconciliation_delivery_refarance_no = tarr[2];
          tDeliveryCompanyPaymentReconciliationDetail.reconciliation_cod_received = parseFloat(tarr[3]);
          tDeliveryCompanyPaymentReconciliationDetail.reconciliation_billable_amount = parseFloat(tarr[4]);
          tDeliveryCompanyPaymentReconciliationDetail.reconciliation_nbt = parseFloat(tarr[5]);
          tDeliveryCompanyPaymentReconciliationDetail.reconciliation_net_amount = parseFloat(tarr[6]);
          tDeliveryCompanyPaymentReconciliationDetail.isSave = 'PENDING';
          this.tDeliveryCompanyPaymentReconciliation.delivery_company_payment_reconciliation_detail_list.push(tDeliveryCompanyPaymentReconciliationDetail);
        }
      }

      //get total rec
      var rec_cod_order_amount_total: number = 0;
      var rec_delivery_charges_total: number = 0;
      var rec_net_amount_total: number = 0;

      for (var tDeliveryCompanyPaymentReconciliationDetail of this.tDeliveryCompanyPaymentReconciliation.delivery_company_payment_reconciliation_detail_list) {
        rec_cod_order_amount_total += tDeliveryCompanyPaymentReconciliationDetail.reconciliation_cod_received;
        rec_delivery_charges_total += tDeliveryCompanyPaymentReconciliationDetail.reconciliation_billable_amount;
        rec_net_amount_total += tDeliveryCompanyPaymentReconciliationDetail.reconciliation_net_amount;
      }

      this.tDeliveryCompanyPaymentReconciliation.rec_cod_order_amount_total = rec_cod_order_amount_total;
      this.tDeliveryCompanyPaymentReconciliation.rec_delivery_charges_total = rec_delivery_charges_total;
      this.tDeliveryCompanyPaymentReconciliation.rec_net_amount_total = rec_net_amount_total;

    }

  }

  savetDeliveryCompanyPaymentReconciliation() {
    if (this.inputForm.invalid) {
      this.snotifyService.error('Fill All Input Text', AppSettings.MESSAGE_WARNING, {
        timeout: 2000,
        showProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true
      });
    } else {
      this.snotifyService.confirm('SAVE EXCEL ?..', AppSettings.MESSAGE_WARNING, {
        timeout: 5000,
        showProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        buttons: [
          {
            text: 'Yes', action: (toast) => {
              this.tDeliveryCompanyPaymentReconciliation.status = 'PENDING';
              this.tDeliveryCompanyPaymentReconciliationService.saveTDeliveryCompanyPaymentReconciliation(this.tDeliveryCompanyPaymentReconciliation).subscribe((data) => {
                if (data > 0) {
                  this.snotifyService.success('Delivery Company Invoice Save Success', AppSettings.MESSAGE_SUCCESS, {
                    timeout: 2000,
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false
                  });
                  this.tDeliveryCompanyPaymentReconciliation = new TDeliveryCompanyPaymentReconciliation();
                  this.inputForm.reset();
                }
              }, (e) => {
                this.snotifyService.error('Delivery Company Invoice Save Error', AppSettings.MESSAGE_ERROR, {
                  timeout: 2000,
                  showProgressBar: false,
                  closeOnClick: false,
                  pauseOnHover: false
                });
              });
            }
          },
          {
            text: 'No', action: (toast) => {
              this.snotifyService.remove(toast.id);
            }, bold: true
          },
        ]
      });
    }
  }

  clearDeliveryCompanyPaymentReconciliation() {
    this.snotifyService.confirm('CLEAR EXCEL ?..', AppSettings.MESSAGE_WARNING, {
      timeout: 5000,
      showProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      buttons: [
        {
          text: 'Yes', action: (toast) => {
            this.tDeliveryCompanyPaymentReconciliation = new TDeliveryCompanyPaymentReconciliation();
            this.inputForm.reset();
          }
        },
        {
          text: 'No', action: (toast) => {
          }, bold: true
        },
      ]
    });
  }


  tDeliveryCompanyPaymentReconciliationAfterSave() {
    this.snotifyService.confirm('SAVE EXCEL ?..', AppSettings.MESSAGE_WARNING, {
      timeout: 5000,
      showProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      buttons: [
        {
          text: 'Yes', action: (toast) => {

            this.tDeliveryCompanyPaymentReconciliation.status = 'RECONCILIATE';
            this.tDeliveryCompanyPaymentReconciliationService.saveTDeliveryCompanyPaymentReconciliation(this.tDeliveryCompanyPaymentReconciliation).subscribe((data) => {
              if (data > 0) {
                this.snotifyService.success('Delivery Company Invoice Save Success', AppSettings.MESSAGE_SUCCESS, {
                  timeout: 2000,
                  showProgressBar: false,
                  closeOnClick: false,
                  pauseOnHover: false
                });
              }
              this.tDeliveryCompanyPaymentReconciliation = new TDeliveryCompanyPaymentReconciliation();
            }, (e) => {
              this.snotifyService.error('Delivery Company Invoice Save Error', AppSettings.MESSAGE_ERROR, {
                timeout: 2000,
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false
              });
            });
          }
        },
        {
          text: 'No', action: (toast) => {
            this.snotifyService.remove(toast.id);
          }, bold: true
        },
      ]
    });
  }

  compareMDeliveryCompany(c1: MDeliveryCompany, c2: MDeliveryCompany): boolean {
    return c1 && c2 ? c1.indexNo === c2.indexNo : c1 === c2;
  }

  tDeliveryCompanyPaymentReconciliationModalSelect() {
    this.tDeliveryCompanyPaymentReconciliationService.findTDeliveryCompanyPaymentReconciliationByDeliveryComapanyIndexNo(
      this.tDeliveryCompanyPaymentReconciliation.delivery_comapany.indexNo).subscribe((data) => {

      this.tDeliveryCompanyPaymentReconciliationList = data;
    }, (e) => {
      console.log('findByReconciliationDeliveryComapanyIndexNo!')
    });
    this.ngxSmartModalService.open('tDeliveryCompanyPaymentReconciliationModal');
  }

  tDeliveryCompanyPaymentReconciliationInvoiceSelect(tDeliveryCompanyPaymentReconciliation: TDeliveryCompanyPaymentReconciliation) {
    this.tDeliveryCompanyPaymentReconciliation = new TDeliveryCompanyPaymentReconciliation();
    this.spinner.show();
    this.tDeliveryCompanyPaymentReconciliationService.findBeforeByIndexNoTDeliveryCompanyPaymentReconciliation(tDeliveryCompanyPaymentReconciliation.index_no).subscribe((data) => {
      console.log(tDeliveryCompanyPaymentReconciliation);
      this.tDeliveryCompanyPaymentReconciliation = data;
      this.getSysTDeliveryCompanyPaymentReconciliationTotal();
      this.spinner.hide();
    }, (e) => {
      this.spinner.hide();
    });

    this.ngxSmartModalService.close('tDeliveryCompanyPaymentReconciliationModal');
  }

  tDeliveryCompanyPaymentReconciliationClear() {
    this.tDeliveryCompanyPaymentReconciliation = new TDeliveryCompanyPaymentReconciliation();
  }

  getSysTDeliveryCompanyPaymentReconciliationTotal(): void {

    var sys_cod_order_amount_total: number = 0;
    var sys_delivery_charges_total: number = 0;
    var sys_net_amount_total: number = 0;

    for (var tDeliveryCompanyPaymentReconciliationAccount of this.tDeliveryCompanyPaymentReconciliation.delivery_company_payment_reconciliation_accounts_list) {
      sys_cod_order_amount_total += tDeliveryCompanyPaymentReconciliationAccount.sys_cod_order_amount;
      sys_delivery_charges_total += tDeliveryCompanyPaymentReconciliationAccount.sys_delivery_charges;
      sys_net_amount_total += tDeliveryCompanyPaymentReconciliationAccount.sys_net_amount;
    }

    this.tDeliveryCompanyPaymentReconciliation.sys_cod_order_amount_total = sys_cod_order_amount_total;
    this.tDeliveryCompanyPaymentReconciliation.sys_delivery_charges_total = sys_delivery_charges_total;
    this.tDeliveryCompanyPaymentReconciliation.sys_net_amount_total = sys_net_amount_total;
  }

}
