import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {UrlPermission} from './view/security/urlPermission/url.permission';
import {LoginComponent} from './view/security/login/login.component';
import {TOnlinePendingOrderPrintComponent} from './view/trasactions/t-online-pending-order-print/t-online-pending-order-print.component';
import {TDeliveryCompanyPaymentReconciliationComponent} from "./view/trasactions/t-delivery-company-payment-reconciliation/t-delivery-company-payment-reconciliation.component";

const routes: Routes = [
  {
    path: 't-online-order-pending-order-print',
    component: TOnlinePendingOrderPrintComponent,
    canActivate: [UrlPermission]
  },
  {
    path: 't-delivery-company-payment-reconciliation',
    component: TDeliveryCompanyPaymentReconciliationComponent,
    canActivate: [UrlPermission]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  // otherwise redirect to profile
  {path: '**', redirectTo: '/login'}
];

@NgModule({
  exports: [RouterModule],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  declarations: []
})
export class AppRoutingModule {
}
